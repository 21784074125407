.tablePdf{
	text-align: center;font-weight: bold;font-size: 11px!important;
}
#DivPrint{
	display: block;
}
.TextTotalInfracion{
	font-size:18px;
	color:#3c3c3c;
	font-weight: bold;
}
.TextTotalInfracionTotal{
	font-size:22px;
	color:red;
	font-weight: bold;
}

.viewStatus{
	font-size: 18px;
	font-weight: bold;
}
.TextTotalInfracionBorder{
	font-size:18px;
	color:#3c3c3c;
	font-weight: bold;
	border-bottom:2px solid #ccc;
	margin-bottom: 4px;
}
.delete-btn{
	background: red;
	border-color:red;
	padding: 15px 20px;
}
.success-btn{
	background: #22c55e;
	border-color:#22c55e;
	padding: 15px 20px;
}
.recibo-btn{
	background: #64748b;
	border-color:#64748b;
	padding: 15px 20px;
}
.warn-btn{
	background: #f97316;
	border-color:#f97316;
	margin-left: 15px!important;
}
.infraccion-border-top{
	
	padding-top: 25px;
}
.desc-btn{
	background: #22c55e;
	border-color:#22c55e;
	margin-left: 15px!important;
}